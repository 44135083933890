.authorizationContainer {
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .contentWrapper {
    // border: 1px solid red;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    position: relative;

    .row {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 6px;

      .line {
        width: 100%;
        height: 1px;
        position: absolute;
        transform: translateX(-50%);
        bottom: -5px;
        left: 50%;
        background-color: #fff;
      }

      span {
        font-size: 25px;
        line-height: 29.35px;
        font-weight: 500;
        // font-weight: 400;
      }

      .smolText {
        font-size: 16px;
        line-height: 18.78px;
        font-weight: 400;
        max-width: 507px;
      }
    }

    .errMsg {
      max-width: 300px;
      position: absolute;
      top: 110%;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      z-index: 10;
    }
  }
}
