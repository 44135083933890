.analysisContainer {
  width: max-content;
  max-width: 600px;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 768.5px) {
    max-width: 100%;
    padding: 50px 0;
  }

  .mainTitle {
    font-size: 25px;
    line-height: 29.35px;
    font-weight: 600;
    // margin-bottom: 34px;
    display: block;
    width: max-content;
  }

  .personaContainer,
  .personhoodContainer,
  .connectedAccounts {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title,
      .text {
        font-size: 16px;
        line-height: 18.78px;
      }

      .title {
        font-weight: 600;
        margin-bottom: 6px;
      }

      .text {
        font-weight: 400;
        max-width: 400px;
        position: relative;
        padding-bottom: 10px;

        .line {
          width: 100%;
          height: 1px;
          position: absolute;
          transform: translateX(-50%);
          bottom: 0;
          left: 50%;
          background-color: #fff;
        }
      }
    }
  }

  .personaContainer {
    .bottom {
      width: 550px;
      height: max-content;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 25px 0;
      margin-left: -18px;

      @media (max-width: 768.5px) {
        flex-direction: column;
        width: max-content;
      }

      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        height: max-content;

        .interests {
          border-radius: 18px;
          width: 260px;
          height: 50px;
          overflow: hidden;
          background-color: rgba(255, 255, 255, 0);
          // transition: 0.25s ease all;

          .interestLabel {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px;
            // border: 1px solid red;
            font-size: 16px;
            line-height: 18.78px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            position: relative;

            svg {
              // transition: 0.25s ease all;
            }

            .line {
              width: calc(100% - 36px);
              height: 1px;
              position: absolute;
              transform: translateX(-50%);
              bottom: 0;
              left: 50%;
              // transition: 0.25s ease all;
            }
          }

          .traits {
            width: 100%;
            height: max-content;
            min-height: max-content;
            // background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0px 18px;
            gap: 0px;
            // transition: 0.25s ease all;

            .trait {
              display: block;
              font-weight: 400;
              // transition: 0.25s ease all;
              font-size: 0px;
              line-height: 0px;
              opacity: 0;
              color: rgba(255, 255, 255, 0);
              // transition-delay: 0.05s;
            }
          }
        }

        .activeInterest {
          background-color: rgba(0, 0, 0, 0.2);
          height: max-content !important;

          .interestLabel {
            svg {
              transform: rotate(180deg) !important;
            }

            .line {
              background-color: #ffffff80;
            }
          }

          .traits {
            padding: 13px 18px !important;
            gap: 13px !important;

            .trait {
              display: block;
              opacity: 1;
              font-size: 16px !important;
              line-height: 18.5px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }

  .personhoodContainer {
    .bottom {
      display: flex;
      // border: 1px solid red;
      justify-content: flex-start;
      align-items: center;
      height: max-content;
      padding: 25px 0;
      gap: 6px;

      .rankBox {
        min-width: 44px;
        min-height: 44px;
        border-radius: 11px;
        background-color: rgba(255, 255, 255, 0.1);
      }

      .active {
        background-color: #fff;
      }

      svg {
        height: 33px;
        width: 33px;
      }
    }
  }

  .connectedAccounts {
    .bottom {
      display: flex;
      // border: 1px solid red;
      justify-content: flex-start;
      align-items: center;
      height: max-content;
      padding: 25px 0;
      gap: 30px;

      svg {
        height: 33px;
        width: 33px;
        cursor: pointer;
      }

      .inactiveLogo {
        color: #ffffff80;
      }

      .activeLogo {
        color: #fff;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
