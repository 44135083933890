.userWarningContainer {
  // border: 5px solid green;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 6px;

    img {
      height: 56px;
    }

    span {
      font-size: 45px;
      line-height: 45px;
      font-weight: 700;
      color: #fff;
    }
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 13px;
  }
}
